<template>
  <div id="print" class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      :pagination-page-size="20"
      url="/data-statistics/elevator-online-statistics/page">
      <el-button
        v-if="$auth('电梯在线率统计导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="班组名称">
            <el-input v-model="filter.teamName" type="iotDeviceModel"></el-input>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="province" label="省" align="center" width="120"></el-table-column>
      <el-table-column prop="city" label="市" align="center" width="120"></el-table-column>
      <el-table-column prop="district" label="区" align="center" width="120"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称" align="center" width="170">
        <template slot="header" slot-scope="">
          楼盘名称 <el-button type="text" icon="el-icon-sort" @click="sort('realEstateName')"></el-button>
        </template>
        <template slot-scope="scope">
          {{scope.row.realEstateName}}
        </template>
      </el-table-column>
      <el-table-column prop="teamName" label="班组名称" align="center" width="170">
        <template slot="header" slot-scope="">
          班组名称 <el-button type="text" icon="el-icon-sort" @click="sort('teamName')"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorCount" label="在保电梯数量" align="center" width="170">
        <template slot="header" slot-scope="">
          在保电梯数量 <el-button type="text" icon="el-icon-sort" @click="sort('elevatorCount')"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="onlineElevatorCount" label="在线电梯数量" align="center" width="170">
        <template slot="header" slot-scope="">
          在线电梯数量 <el-button type="text" icon="el-icon-sort" @click="sort('onlineElevatorCount')"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="offlineElevatorCount" label="离线电梯数量" align="center" width="170">
        <template slot="header" slot-scope="">
          离线电梯数量 <el-button type="text" icon="el-icon-sort" @click="sort('offlineElevatorCount')"></el-button>
        </template>
        <template slot-scope="scope">
          <el-button type="text" @click="goToDetail(scope.row)">{{scope.row.offlineElevatorCount}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="onlineRate" label="在线率" align="center">
        <template slot="header" slot-scope="">
          在线率 <el-button type="text" icon="el-icon-sort" @click="sort('onlineRate')"></el-button>
        </template>
        <template slot-scope="scope">
          {{scope.row.onlineRate}}
        </template>
      </el-table-column>
      <!--      <el-table-column prop="useTypeDesc" label="区分" align="center"></el-table-column>-->
    </vm-table>
  </div>
</template>
<script>

  import {export2Excel, getWidth} from "@/util";
  import print from  "print-js";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";
  export default {
    data() {
      return {
        loading:false,
        filter: {
          name: "",
          maintenanceType:"as_need",
          realEstateName:"",
          companyId: loginUtil.getCompanyCode(),
          arr:[{
            orderColumnName:"onlineRate",
            asc:0,
          }],
          orderColumnNames:"onlineRate|0",
        },
        senior: false,
        data:[],
        width:200,
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      exportExcelData() {
        const title={
          province:"省",
          city:"市",
          district:"区",
          realEstateName:"楼盘",
          teamName:"班组名称",
          elevatorCount:"在保电梯数量",
          onlineElevatorCount:"在线电梯数量",
          offlineElevatorCount:"离线电梯数量",
          onlineRate:"在线率",
        };
        this.$http.get("data-statistics/elevator-online-statistics/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`数据统计-在线率-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      sort(name) {
        if (this.filter.arr.some(item=>item.orderColumnName===name)) {
          const arr = this.filter.arr.map(item=>{
            console.log(item.orderColumnName,name);
            if (item.orderColumnName===name) {
              item.asc=item.asc?0:1;
            }
            return item;
          });
          this.filter.arr=arr;
        }else {
          this.filter.arr.push({
            orderColumnName:name,
            asc:0,
          });
        }
        let str = "";
        this.filter.arr.forEach(item=>{
          str+=item.orderColumnName+"|"+item.asc+"&";
        });
        this.filter.orderColumnNames = str;
        this.getList();
      },
      printSomething() {
        print({
          printable:"print",
          type:"html",
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      deleteRow(row) {
        this.$confirm(this.$l("common.deleteTip", "确定删除{key}吗？", {key: row.name}), this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/elevator-archive/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      gotoMonitor(elevatorId) {
        let routeUrl = this.$router.resolve({
          path: "/monitor/LiftMonitor",
          query: {elevatorId},
        });
        window.open(routeUrl.href, "_blank");
      },
      goToDetail({realEstateName}) {
        this.$router.push({path:"/datastatistics/elevatoroffline",query:{realEstateName}});
      },
      handleSeniorClick() {
        this.senior=!this.senior;
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-real-estate");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {

        if (e.other!=="district") {
          this.filter.realEstateName=e.label;
          this.getList(-1);
        }

      },

    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 1080px;
  overflow-y: auto;
}
</style>
